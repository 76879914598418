.box {
  background: #1c2233;
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  padding: 1rem 2rem;
  color: white;
}

.box > div {
  padding: 40px;
}

.textLead {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
  margin: 0 20px 20px 20px;
}

.logo {
  width: 150px;
  object-fit: contain;
  display: block;
  margin: 30px auto 30px auto;
}

.btnSecondary {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(242 85 116 / var(--tw-bg-opacity));
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  font-size: 1.1rem;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  cursor: pointer;
  text-align: center;
  max-width: 250px;
  margin: 0 auto 20px auto;
}

.btnSecondary:hover {
  opacity: 0.95;
}

@media only screen and (max-width: 700px) {
  .textLead {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 600px) {
  .textLead {
    font-size: 1rem;
  }

  .btnSecondary {
    font-size: 1rem;
    padding: 0.7rem;
  }

  .box {
    padding: 1rem;
  }
}

@media only screen and (max-width: 510px) {
  .box {
    padding: 2rem;
    max-width: none;
    width: 80%;
  }
}
